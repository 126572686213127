import * as React from "react";

const AboutUsPage = () => {
  return (
    <div
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: "30px",
      }}
    >
      <h1
        style={{ textAlign: "center", fontSize: "36px", marginBottom: "30px" }}
      >
        About Us
      </h1>
      <p style={{ fontSize: "18px", lineHeight: "1.5" }}>
        We specialize in the art of Venetian plastering, bringing timeless
        beauty and elegance to any space. Our skilled craftsmen have years of
        experience creating stunning finishes for both residential and
        commercial properties. From traditional techniques to modern designs, we
        can help you achieve the perfect look for your project. Browse our
        portfolio to see some of our past work, and contact us today to discuss
        your vision and schedule a consultation."
      </p>
      <p style={{ fontSize: "18px", lineHeight: "1.5" }}>
        If you're looking for a professional and experienced Venetian plasterer,
        look no further! I am a skilled craftsman with years of experience in
        the art of Venetian plastering. I can create a wide range of finishes,
        from smooth and polished to textured and aged, and I specialize in
        traditional and modern techniques. I offer a variety of services,
        including wall and ceiling finishes, flooring finishes, restoration and
        conservation of existing plasterwork, color matching, and specialist
        finishes like Travertino, Marmorino, Tadelakt, and Micro-Cement. I work
        closely with architects, designers, and clients to ensure that the final
        result meets their specific requirements and expectations. I take great
        pride in my work and am committed to providing the highest quality
        services to my clients. I am fully insured and guarantee all of my work.
        My prices are competitive and I am always happy to provide a free quote.
        So if you're looking for an experienced and skilled Venetian plasterer,
        give me a call. I would be more than happy to discuss your project and
        provide you with a free quote.
      </p>
      <p style={{ fontSize: "18px", lineHeight: "1.5", marginTop: "30px" }}>
        <h1
          style={{
            textAlign: "center",
            fontSize: "36px",
            marginBottom: "30px",
          }}
        >
          My services
        </h1>
        <ol>
          <li>
            Wall and ceiling finishes: They can create a variety of finishes,
            including smooth, polished, textured, and aged, on walls and
            ceilings.
          </li>
          <li>
            Flooring finishes: They can apply Venetian plaster to floors to
            create a smooth, durable surface that is easy to clean and maintain.
          </li>
          <li>
            Restoration and conservation: They can restore and conserve existing
            plasterwork in historic buildings, ensuring that the original
            finishes are preserved.
          </li>
          <li>
            Color matching: They can match existing plasterwork and create
            custom colors to match the client's desired aesthetic.
          </li>
          <li>
            Specialist finishes: They can create specialist finishes such as
            Travertino, Marmorino, Tadelakt, and Micro-Cement.
          </li>
        </ol>
      </p>
    </div>
  );
};

export default AboutUsPage;
